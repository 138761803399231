import { deleteItems, getItems, saveItems, updateItems } from "../../api/items";
import { setLoading } from "./global";

export const getItemData = (value) => async (dispatch) => {
      try {
        setLoading(true)
        const response = await getItems(value);
        dispatch({type: 'GET_ITEMS_SUCCESS', value: response.data});
        setLoading(false)
      } catch (error) {
        dispatch({type: 'GET_ITEMS_ERROR', value: []});
        setLoading(false)
      }
  };

export const saveItemData = (value) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await saveItems(value);
        dispatch({type: 'SAVE_ITEMS_SUCCESS', value: response.data});
        setLoading(false)
    } catch (error) {
        dispatch({type: 'SAVE_ITEMS_ERROR', value: []});
        setLoading(false)
    }
};

export const updateItemData = (value, id) => async (dispatch) => {
      try {
        setLoading(true)
        const response = await updateItems(value, id);
        dispatch({type: 'UPDATE_ITEMS_SUCCESS', value: response.data});
        setLoading(false)
      } catch (error) {
        dispatch({type: 'UPDATE_ITEMS_ERROR', value: []});
        setLoading(false)
      }
  };

  
export const clearGetAllDataItems = () => async (dispatch) => {
    dispatch({type: 'GET_ITEMS_SUCCESS', value: []});
  };

export const deleteItemData = (value) => async (dispatch) => {
      try {
        setLoading(true)
        const response = await deleteItems(value);
        dispatch({type: 'DELETE_ITEMS_SUCCESS', value: response.data});
        setLoading(false)
      } catch (error) {
        dispatch({type: 'DELETE_ITEMS_ERROR', value: []});
        setLoading(false)
      }
  };
