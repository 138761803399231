import { useEffect, useRef, useState } from 'react';

const UploadPhoto = (props) => {
  const defaultPhoto = '/defaultProfile.jpg';
  let photo = defaultPhoto;

  const [preview, setPreview] = useState(null);
  if (preview) {
    photo = preview;
  }
  //    else {
  //     if (users.photo) {
  //       if (validator.isURL(users.photo)) {
  //         photo = users.photo;
  //       } else {
  //         photo = defaultPhoto;
  //       }
  //     } else {
  //       photo = defaultPhoto;
  //     }
  //   }

  const [media, setMedia] = useState(null);

  const fileInputRef = useRef();
  useEffect(() => {
    if (media) {
      if (media.size >= 100000) {
        alert('ukuran photo terlalu besar');
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(media);
      }
    } else {
      setPreview(null);
    }
  }, [media]);

  return (
    <div className="mx-auto">
      <label>
        {props.label}
        {props.optional && <span className="text-sm font-normal text-gray-400">(Optional)</span>}
      </label>
      <div className="w-56 duration-200 cursor-pointer hover:shadow-md hover:outline-0 hover:bg-gray-50">
        <img
          src={photo}
          alt=""
          style={{ objectFit: 'cover' }}
          className="w-56 h-56 mx-auto overflow-hidden align-middle rounded-md"
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        />
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept="image/png, image/jpg, image/jpeg"
        onChange={(event) => {
          const file = event.target.files[0];
          if (file && file.type.substring(0, 5) === 'image') {
            setMedia(file);
          } else {
            setMedia(null);
          }
        }}
      />
    </div>
  );
};

export default UploadPhoto;
