import {combineReducers} from 'redux';

import {globalReducer} from './global';
import { loginReducer } from './auth';
import { itemsReducer } from './items';

const reducers = combineReducers({
  globalReducer,
  loginReducer,
  itemsReducer
});

export default reducers;
