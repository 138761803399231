import { Navigate, Outlet } from 'react-router-dom';
import { setLogin } from '../redux/action';
import store from '../redux/store';

export default function ProtectedRoutes() {
  const accessToken = localStorage.getItem('access_token');

  const { dispatch } = store;
  dispatch(setLogin(accessToken ? true : false));

  return accessToken ? <Outlet /> : <Navigate replace to={'/login'} />;
}
