import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  ButtonTextIcon,
  CustomTable,
  Input,
  ModalsDecision,
  Pagination,
  Sidebar,
} from '../component';
import ModalsAddItems from '../component/modalsAddItems';
import { useDispatch, useSelector } from 'react-redux';
import { getItemData } from '../redux/action';

export default function Index() {
  const dispatch = useDispatch();
  const { itemsData } = useSelector((state) => state.itemsReducer);

  document.title = 'List Barang';

  const [search, setSearch] = useState({ search: '' });

  const [totalPage, setTotalPage] = useState(0);

  const [modals, setModals] = useState('');

  const [filter] = useState({
    itemName: '',
    page: 1,
    size: 10,
  });

  useEffect(() => {
    dispatch(getItemData(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    manageDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsData]);

  const setModal = (e) => {
    e.preventDefault();
    setModals('');
  };

  const headCells = [
    {
      id: 'nameItems',
      label: 'Nama Barang',
    },
    {
      id: 'photo',
      label: 'Foto',
    },
    {
      id: 'buyPrice',
      label: 'Harga Beli',
    },
    {
      id: 'soldPrice',
      label: 'Harga Jual',
    },
    {
      id: 'stock',
      label: 'Stok',
    },
    {
      id: 'action',
      label: 'Action',
    },
  ];

  const [datas, setDatas] = useState({
    no: 0,
    nameItems: '',
    photo: '',
    buyPrice: '',
    soldPrice: '',
    stock: '',
  });

  const manageDataTable = () => {
    if (itemsData.length !== 0) {
      const dataNew = [];
      // eslint-disable-next-line array-callback-return
      itemsData.items.map((res) => {
        dataNew.push({
          id: res.id ?? '',
          nameItems: res.itemName ?? '',
          photo: res.photo ?? '',
          buyPrice: res.buyPrice ?? '',
          soldPrice: res.salePrice ?? '',
          stock: res.stock ?? '',
        });
      });
      setTotalPage(itemsData.meta.totalPages);
      setDatas(dataNew);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const fetchDataTable = (page, keyword = null) => {
    const theKeyword = () => {
      if (keyword?.length > 0) {
        return keyword;
      } else if (filter.name?.length > 0) {
        return filter.name;
      } else {
        return null;
      }
    };

    dispatch(
      itemsData({
        ...filter,
        itemName: theKeyword(),
        page: page,
      }),
    );
  };

  const handleChange = (e) => {
    let newVal = { ...search };
    newVal[e.target.name] = e.target.value;
    setSearch(newVal);
  };

  return (
    <Sidebar>
      <div className="px-5 md:px-12">
        <div className="flex items-center w-full ">
          {modals === 'decision' && (
            <ModalsDecision
              buttonTitleOne={'Yes'}
              buttonTitleTwo={'N'}
              title={
                'Yakin untuk data menyimpan wjerg wnegi wrgn rug ier gi erg wieugfwebf weif iuwef eigf iew ew?'
              }
              closeButton={setModal}
              onSubmit={setModal}
            />
          )}
          {modals === 'addItems' && <ModalsAddItems onClose={setModal} />}
          {/* {modals === 'updateItems' && (
            <ModalsUpdateItems onClose={setModal} />
          )} */}
          <div className="flex flex-col w-screen space-y-6">
            <div className="flex flex-row justify-end space-x-4">
              <ButtonTextIcon
                icon={<AiOutlinePlus />}
                buttonTitle={'Tambah'}
                iconPosition="start"
                onClick={() => setModals('addItems')}
              />
              <Input
                type="search"
                className={'py-2'}
                placeholder="Search..."
                onClick={''}
                onChange={handleChange}
                name={'search'}
                value={search.search}
              />
            </div>
            {datas.length >= 1 && <CustomTable rows={datas} headCells={headCells} />}

            {datas.length >= 1 && <Pagination currentPage={1} totalPage={totalPage} />}
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
