import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import Index from './Pages/Index';
import Login from './Pages/Login';
import ProtectedRoutes from './routes';

function App() {
  const accessToken = localStorage.getItem('access_token');

  // useEffect(() => {
  //   localStorage.setItem('current_router', window.location.pathname);
  // }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={accessToken ? <Navigate to={'/'} /> : <Login />} />
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<Index />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
