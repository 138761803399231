import Modals from '../modals';

const ModalsGeneral = (props) => {
  return (
    <Modals
      toggle={props.toggle}
      xToggle={props.xToggle}
      onCloseX={props.onCloseX}
      modalTitle={props.modalTitle}
    >
      {props.children}
    </Modals>
  );
};

export default ModalsGeneral;
