import { useNavigate } from 'react-router-dom';
import Button from '../button';

const Sidebar = (props) => {
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem('profile'));
  const logout = async () => {
    await localStorage.removeItem('access_token');
    await localStorage.removeItem('profile');
    await navigate('/login');
    await navigate(0);
  };
  return (
    <div className="flex flex-row h-screen">
      <div className="w-[20%] bg-gray-200 bg-opacity-40 rounded-r-lg flex justify-center items-center shadow-xl flex-col">
        <div className="w-[80%] bg-white rounded-md px-4 space-y-4 py-4 mb-auto mt-10">
          {/* <Button className="w-full" buttonTitle={'List Barang'} /> */}
          <div className="flex flex-row space-x-3">
            {/* <div className="w-20 h-20 bg-black rounded-md" /> */}
            <div className="flex flex-col">
              <div className="my-auto">
                <p>{dataUser.username}</p>
              </div>
            </div>
          </div>
          {/* <div>Logout</div> */}
        </div>
        <div className="w-[80%] rounded-md px-4 space-y-4 py-4 mt-auto mb-10">
          {/* <Button className="w-full" buttonTitle={'List Barang'} /> */}
          <Button className="w-full" buttonTitle={'Logout'} onClick={() => logout()} />
          {/* <div>Logout</div> */}
        </div>
      </div>
      <div className="w-[80%] flex flex-col">
        <div className="fixed w-full bg-gray-200 -z-10">
          <div className="px-8 py-10 text-xl font-medium text-left">List Barang</div>
        </div>
        <div className="w-full pt-4 mb-1 overflow-hidden mt-28">{props.children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
