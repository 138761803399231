import React from 'react';

const Button = (props) => {
  return (
    <button
      id={props.id}
      className={`${props.className} min-h-[40px] px-4 py-2 text-white bg-blue-900 rounded-md hover:bg-blue-600`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonTitle}
    </button>
  );
};
export default Button;
