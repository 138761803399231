const initItems = {
    itemsData: [],
    itemsDataSave: [],
    itemsDataUpdate: [],
    itemsDataDelete: [],
  };
  
  export const itemsReducer = (state = initItems, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'GET_ITEMS_SUCCESS': {
        return { ...state, itemsData: action.value };
      }
      case 'GET_ITEMS_ERROR': {
        return { ...state, itemsData: action.value };
      }
      case 'SAVE_ITEMS_SUCCESS': {
        return { ...state, saveItemsData: action.value };
      }
      case 'SAVE_ITEMS_ERROR': {
        return { ...state, saveItemsData: action.value };
      }
      case 'UPDATE_ITEMS_SUCCESS': {
        return { ...state, saveItemsData: action.value };
      }
      case 'UPDATE_ITEMS_ERROR': {
        return { ...state, saveItemsData: action.value };
      }
      case 'DELETE_ITEMS_SUCCESS': {
        return { ...state, saveItemsData: action.value };
      }
      case 'DELETE_ITEMS_ERROR': {
        return { ...state, saveItemsData: action.value };
      }
    }
    return state;
  };