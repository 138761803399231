import ModalsFrame from '../modalsFrame';

const ModalsDecision = (props) => {
  return (
    <ModalsFrame
      oneButton={false}
      className={'max-w-xl min-w-[50%] px-10'}
      classNameButtonTwo={'bg-red-500'}
      title={props.title}
      buttonTitleOne={props.buttonTitleOne}
      buttonTitleTwo={props.buttonTitleTwo}
      closeButton={props.closeButton}
      onSubmit={props.onSubmit}
      xToggle={false}
    />
  );
};

export default ModalsDecision;
