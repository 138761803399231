import axios from 'axios';
import store from '../redux/store';
import { setLogin } from '../redux/action';

const { dispatch } = store;

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: '*/*',
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access_token') || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

api.interceptors.response.use(
  (response) => {
    // console.log('interceptors-response: ', response)
    return response;
  },
  async (error) => {
    console.log('interceptors-error:delta ', error.response);
    if (error.response.status === 401) {
      // Unauthorized
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('username');
      dispatch(setLogin(false));
    }
    return Promise.reject(error);
  },
);

export default api;
