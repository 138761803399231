const Shadow = (props) => {
  return (
    <>
      <div
        onClick={props.toggle}
        id="shadow"
        className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-30"
      >
        <main className="max-w-full" onClick={(e) => e.stopPropagation()}>
          {props.children}
        </main>
      </div>
    </>
  );
};

export default Shadow;
