import Button from '../button';
import ModalsGeneral from '../modalsGeneral';

const ModalsFrame = (props) => {
  return (
    <ModalsGeneral
      toggle={props.toggle}
      xToggle={props.xToggle}
      onCloseX={props.onCloseX}
      modalTitle={props.modalTitle}
    >
      <div className={`flex flex-col ${props.className}`}>
        <div className="flex items-center justify-center mt-2 mb-8 text-center">
          {props.xToggle ? <>{props.children}</> : <div>{props.title}</div>}
        </div>
        <div className="flex flex-row justify-center w-full mb-2 space-x-2">
          {!props.oneButton && (
            <>
              <Button
                id={'close'}
                className={`w-full  ${props.classNameButtonTwo}`}
                buttonTitle={props.buttonTitleTwo}
                onClick={props.closeButton}
                type={'submit'}
              />
              <Button
                className={`w-full ${props.classNameButtonOne}`}
                buttonTitle={props.buttonTitleOne}
                onClick={props.onSubmit}
              />
            </>
          )}
        </div>
      </div>
    </ModalsGeneral>
  );
};

export default ModalsFrame;
