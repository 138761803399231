import { Link, useNavigate } from 'react-router-dom';
import { Button, Input } from '../component';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginData } from '../redux/action';

const Login = () => {
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  const [loginDatas, setLoginDatas] = useState({
    username: '',
    password: '',
  });

  const { dataLogin } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    setAccessToken(localStorage.getItem('access_token'));
    // protects when from the dashboard returns to the login page
    const currentRouter = JSON.parse(localStorage.getItem('current_router')) ?? '/';
    const checkingRouter = currentRouter === '/login' ? '/' : currentRouter;

    accessToken ? navigate(checkingRouter) : navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dataLogin]);

  const dispatch = useDispatch();

  const login = () => {
    dispatch(loginData(loginDatas));
  };

  const handleChange = (e) => {
    let newVal = { ...loginDatas };
    newVal[e.target.name] = e.target.value;
    setLoginDatas(newVal);
  };

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex flex-col space-y-5">
        <div className="text-center">
          <p>Login</p>
        </div>
        <div>
          <Input
            label="Username"
            type="text"
            placeholder="username"
            name="username"
            value={loginDatas.username}
            onChange={handleChange}
          />
        </div>

        <div>
          <Input
            label="Password"
            type="password"
            placeholder="Password"
            name="password"
            value={loginDatas.password}
            onChange={handleChange}
          />
        </div>
        <Link className="mt-3" to={'/'}>
          <Button className="w-full" onClick={() => login()} buttonTitle={'Login'} />
        </Link>
      </div>
    </div>
  );
};

export default Login;
