import React from 'react';
import Button from '../button';

const ButtonTextIcon = (props) => {
  return (
    <Button
      type={props.type}
      onClick={props.onClick}
      buttonTitle={
        <div
          className={`flex items-center justify-center ${
            props.iconPosition === 'start' ? 'flex-row-reverse' : 'flex-row'
          }`}
        >
          <div className="mx-1">{props.buttonTitle}</div>
          <div className="mx-1">{props.icon}</div>
        </div>
      }
    />
  );
};

export default ButtonTextIcon;
