const { default: ModalsFrame } = require('../modalsFrame');

const ModalsForms = (props) => {
  return (
    <ModalsFrame
      oneButton={true}
      className={`${props.className} max-w-full w-full min-w-fit px-5 h-fit`}
      modalTitle={props.modalTitle}
      onCloseX={props.onClose}
      xToggle={true}
    >
      {props.children}
    </ModalsFrame>
  );
};

export default ModalsForms;
