import Button from '../button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { useState } from 'react';

const Pagination = (props) => {
  //   const itemPerPage = 10;
  const totalPage = props.totalPage;
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  return (
    <div className="flex items-center justify-center space-x-1">
      <Button
        className={`h-11 ${
          totalPage - totalPage + 1 === currentPage && 'bg-gray-300 hover:bg-gray-300'
        }`}
        onClick={() => setCurrentPage(currentPage - 1)}
        buttonTitle={<IoChevronBackSharp />}
        disabled={totalPage - totalPage + 1 === currentPage ? true : false}
      />
      {totalPage - totalPage + 1 === currentPage ? (
        <></>
      ) : (
        <Button
          className="h-11"
          onClick={() => setCurrentPage(totalPage - totalPage + 1)}
          buttonTitle={'...'}
        />
      )}
      {[...Array(totalPage)]
        // .filter((data2, index) => index(-1))
        .map((data, index) => {
          if (index + 1 === currentPage) {
            return <Button key={index} className="bg-blue-500 h-11" buttonTitle={index + 1} />;
          }
          // console.log(index.slice(2, 7));
          return (
            <Button
              key={index}
              className="h-11"
              onClick={() => setCurrentPage(index + 1)}
              buttonTitle={index + 1}
            />
          );
          // return <Button key={index} className="h-11" buttonTitle={index + 1} />;
        })}
      {totalPage === currentPage ? (
        <></>
      ) : (
        <Button className="h-11" onClick={() => setCurrentPage(totalPage)} buttonTitle={'...'} />
      )}
      <Button
        className={`h-11 ${totalPage === currentPage && 'bg-gray-300 hover:bg-gray-300'}`}
        onClick={() => setCurrentPage(currentPage + 1)}
        buttonTitle={<IoChevronBackSharp className={'rotate-180'} />}
        disabled={totalPage === currentPage ? true : false}
      />
    </div>
  );
};

export default Pagination;
