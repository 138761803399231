import React from 'react';
import Button from '../button';

const ButtonIcon = (props) => {
  return (
    <Button
      type={props.type}
      onClick={props.onClick}
      className={props.className}
      buttonTitle={<div className="flex items-center justify-center">{props.icon}</div>}
    />
  );
};

export default ButtonIcon;
