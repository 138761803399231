import { BsTrash } from 'react-icons/bs';
import ButtonIcon from '../buttonIcon';
import { AiFillEye } from 'react-icons/ai';
import { useState } from 'react';
import ModalsDecision from '../modalsDecision';
import { useDispatch } from 'react-redux';
import { deleteItemData, getItemData } from '../../redux/action';
import ModalsUpdateItems from '../modalsUpdateItems';

const CustomTable = (props) => {
  const [dataOne, setDataOne] = useState({});
  const [modals, setModals] = useState('');

  const dispatch = useDispatch();
  const deleteData = async (id) => {
    await dispatch(deleteItemData(id));
    await setModals('');
    await dispatch(getItemData());
  };

  return (
    <div>
      {modals === 'details' && <ModalsUpdateItems data={dataOne} onClose={() => setModals('')} />}
      {modals === 'delete' && (
        <ModalsDecision
          data={dataOne}
          closeButton={() => setModals('')}
          onSubmit={() => deleteData(dataOne.id)}
          title="Yakin akan menghapus data? Jika ya data tidak bisa dikembalikan"
          buttonTitleOne="Ya, Yakin"
          buttonTitleTwo="Tidak"
        />
      )}
      <table className="table">
        <thead>
          <tr>
            {props.headCells.map((headCell) => (
              <th key={headCell.id}>{headCell.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.rows.slice().map((row, index) => {
            return (
              <tr key={index}>
                {props.headCells.map((e, indexE) => {
                  if (e.id === 'action') {
                    return (
                      <td key={indexE}>
                        <div className="flex flex-row items-center justify-center space-x-1">
                          <ButtonIcon
                            icon={<AiFillEye />}
                            onClick={() => {
                              setModals('details');
                              setDataOne(row);
                            }}
                          />
                          <ButtonIcon
                            className={'bg-red-600'}
                            icon={<BsTrash />}
                            onClick={() => {
                              setModals('delete');
                              setDataOne(row);
                            }}
                          />
                        </div>
                      </td>
                    );
                  } else if (e.id === 'photo') {
                    return (
                      <td key={indexE}>
                        <div className="flex justify-center">
                          <div className="w-10 h-10 bg-black rounded-lg" />
                        </div>
                      </td>
                    );
                  }
                  return <td key={e.id}>{row[e.id]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
