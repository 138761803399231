import React from 'react';
import { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSearch } from 'react-icons/ai';

const Input = (props) => {
  const [passwordSee, setPasswordSee] = useState(false);

  const passwordEvent = (e) => {
    setPasswordSee(!passwordSee);
  };

  return (
    <div>
      {props.label && (
        <div className="flex justify-start mb-2">
          <label className="font-semibold">{props.label}</label>
        </div>
      )}
      <div>
        {props.type === 'text' && (
          <input
            type={'text'}
            className={`${props.className} w-full py-3 px-6 rounded-md outline-black outline-2 outline `}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
          />
        )}
        {props.type === 'password' && (
          <div className="relative flex items-center justify-center">
            <input
              type={passwordSee ? 'text' : 'password'}
              className={`${props.className} w-full py-3 px-6 rounded-md outline-black outline-2 outline `}
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              placeholder={props.placeholder}
              onKeyDown={props.onKeyDown}
            />
            <button
              onClick={passwordEvent}
              className={`absolute right-0 mr-3 text-2xl cursor-pointer stroke-black black`}
            >
              {passwordSee ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        )}
        {props.type === 'search' && (
          <div className="relative flex items-center justify-center">
            <input
              type={'text'}
              className={`${props.className} w-full py-3 pl-6 pr-10 rounded-md outline-black outline-2 outline `}
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              placeholder={props.placeholder}
            />
            <button className={`absolute right-0 mr-3 text-2xl cursor-pointer stroke-black  black`}>
              <AiOutlineSearch onClick={props.onClick} strokeWidth={4} />
            </button>
          </div>
        )}
        {props.type === 'icon' && (
          <div className="relative flex items-center justify-center">
            <input
              type={'text'}
              className={`${props.className} w-full py-3 ${
                props.position === 'right' ? 'pl-6 pr-11' : 'pl-11 pr-6'
              } rounded-md outline-black outline-2 outline `}
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              placeholder={props.placeholder}
            />
            <div
              className={`absolute ${
                props.position === 'right' ? 'right-0 mr-3' : 'left-0 ml-3'
              } text-2xl stroke-black  black`}
            >
              {props.theIcon ? props.theIcon : <AiOutlineSearch />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Input;
