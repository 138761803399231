import { login } from "../../api/auth";
import { setLoading } from "./global";

export const loginData = (payload) => async (dispatch) => {
      try {
        const response = await login(payload);
        localStorage.setItem('access_token',response.data.access_token)
        localStorage.setItem('profile', JSON.stringify(response.data.profile))
        dispatch({type: 'LOGIN_SUCCESS', value: response.data});
        setLoading(false)
      } catch (error) {
        dispatch({type: 'LOGIN_ERROR', value: []});
        setLoading(false)
      }
  };
