export const setLoading = (value) => {
    return { type: 'SET_LOADING', value };
  };
  export const setLogin = (value) => {
    return { type: 'SET_LOGIN', value };
  };
  

export const setPages = (value) => {
  return {type: 'SET_PAGE', value};
};