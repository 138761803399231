const initLogin = {
    dataLogin: '',
  };
  
  export const loginReducer = (state = initLogin, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'LOGIN_SUCCESS': {
        return {...state, dataLogin: action.value};
      }
      case 'LOGIN_ERROR': {
        return {...state, dataLogin: action.value};
      }
    }
    return state;
  };
  