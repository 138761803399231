import { useState } from 'react';
import Button from '../button';
import Input from '../forms';
import ModalsForms from '../modalsForms';
import UploadPhoto from '../uploadPhoto';
import { useDispatch } from 'react-redux';
import { saveItemData } from '../../redux/action';
import { useNavigate } from 'react-router-dom';

const ModalsAddItems = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    photo: 'photo',
    itemName: '',
    buyPrice: '',
    salePrice: '',
    stock: '',
  });

  const handleChange = (e) => {
    let newVal = { ...itemData };
    newVal[e.target.name] = e.target.value;
    setItemData(newVal);
  };

  const handlePriceBuy = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setItemData({ ...itemData, buyPrice: e.target.value });
    }
  };

  const handlePriceSold = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setItemData({ ...itemData, salePrice: e.target.value });
    }
  };

  const handleStock = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setItemData({ ...itemData, stock: e.target.value });
    }
  };

  const save = async () => {
    await dispatch(saveItemData(itemData));
    navigate(0);
  };

  return (
    <ModalsForms onClose={props.onClose} modalTitle={'Tambah Barang'}>
      <div className="flex flex-col w-full space-y-4 h-fit">
        {/* <div className="w-full bg-gray-900 h-52" /> */}
        <UploadPhoto />
        <Input
          type={'text'}
          label={'Nama Barang'}
          onChange={handleChange}
          value={itemData.itemName}
          name={'itemName'}
          className={'pl-2 pr-2'}
        />
        <Input
          type={'text'}
          label={'Harga Beli'}
          onChange={handlePriceBuy}
          value={itemData.buyPrice}
          name={'itemBuyPrice'}
          className={'pl-2 pr-2'}
        />
        <Input
          type={'text'}
          label={'Harga Jual'}
          onChange={handlePriceSold}
          value={itemData.salePrice}
          name={'itemSoldPrice'}
          className={'pl-2 pr-2'}
        />
        <Input
          type={'text'}
          label={'Stok'}
          onChange={handleStock}
          value={itemData.stock}
          name={'stock'}
          className={'pl-2 pr-2'}
        />
        <div className="mt-5 flex flex-col w-full space-y-3">
          <Button
            buttonTitle="Simpan"
            onClick={() => save()}
            type="submit"
            className="w-full py-4"
          />
          <Button
            buttonTitle="Kembali"
            onClick={props.onClose}
            type="submit"
            className="w-full py-4 bg-red-500"
          />
        </div>
      </div>
    </ModalsForms>
  );
};

export default ModalsAddItems;
