import React from 'react';
import Shadow from '../shadow';
import ButtonIcon from '../buttonIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Modals = (props) => {
  return (
    <>
      <Shadow toggle={props.toggle}>
        <div className="z-20 flex items-center justify-center w-full px-4 py-6 bg-white rounded-md min-w-fit">
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-center flex-shrink justify-items-center">
              <div className="pl-10 mt-1 mr-auto text-xl font-semibold">{props.modalTitle}</div>
              {props.xToggle && (
                <div className="pr-8">
                  <ButtonIcon
                    onClick={props.onCloseX}
                    className="px-0 ml-4 mr-2 text-gray-900 bg-transparent hover:text-red-500 hover:bg-transparent"
                    icon={<AiOutlineCloseCircle size={25} />}
                  />
                </div>
              )}
            </div>
            <div>{props.children}</div>
          </div>
        </div>
      </Shadow>
    </>
  );
};

export default Modals;
